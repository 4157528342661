@import '~app/mixins';

.logo {
  width: 102px;
  height: 102px;
  border: 1px solid #333;
  border-radius: 50%;
  box-shadow: inset 0 1px 0 0 #ececec;
  margin-bottom: 4px;

  .logoFrame {
    width: 100px;
    height: 100px;
    overflow: hidden;
    border: 1px solid white;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &.small {
    width: 62px;
    height: 62px;

    .logoFrame {
      width: 60px;
      height: 60px;
      border-color: transparent;
    }
  }
}
